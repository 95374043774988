import { Box, Link, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ButtonWithAnalytics from "../ButtonWithAnalytics";
import { useEffect, useState } from "react";
import {
  trackEvent,
  trackPageViewV2,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import { generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage } from "@/services/core-api-adapter";
import FullScreenIframeDialog from "../FullScreenIframeDialog";
import DefaultDialog from "../DefaultDialog";
import IconLoader from "../IconLoader";

type WhatsappBreakoutType = "Ampath";

interface WhatsappBreakoutDisclaimerModalProps {
  type: WhatsappBreakoutType;
  isOpen: boolean;
  onClose: () => void;
}

function WhatsappBreakoutDisclaimerModal({
  type,
  isOpen,
  onClose,
}: WhatsappBreakoutDisclaimerModalProps) {
  const { t } = useTranslation();

  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: `${type} Whatsapp breakout disclaimer`,
    });
    trackUserInteraction({
      linkText: `${type} Whatsapp breakout | disclaimer`,
      linkIntent: "informational",
      linkScope: "button",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }
  return (
    <DefaultDialog
      label={t("common.disclaimer")}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <Stack textAlign="center" spacing={2}>
        <Typography variant="h3" fontWeight={600}>
          {t("common.disclaimer")}
        </Typography>

        {(
          t<any, any, string[]>(
            `WhatappBreakout.${type}.disclaimer.modal.body`,
            {
              returnObjects: true,
            }
          ) as []
        ).map((text: string, index: number) => (
          <Typography
            variant="body2"
            color="inherit"
            key={String(text + index)}
          >
            {text}
          </Typography>
        ))}

        <Typography variant="body2" color="inherit">
          <Trans i18nKey={`WhatappBreakout.${type}.disclaimer.modal.footer`}>
            <Link
              sx={{ color: "primary", fontWeight: 900 }}
              href="/"
              onClick={onTermsLinkClick}
            ></Link>
          </Trans>
        </Typography>
      </Stack>

      <FullScreenIframeDialog
        title={t(
          "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PLATFORM.documentTitle"
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage()}
      />
    </DefaultDialog>
  );
}

interface WhatsappBreakoutProps {
  provider: WhatsappBreakoutType;
  logo: string;
  url: string;
  onBackButtonClick: () => void;
}

export default function WhatsappBreakout({
  onBackButtonClick,
  url,
  provider,
  logo,
}: WhatsappBreakoutProps) {
  const { t } = useTranslation();
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    trackPageViewV2({
      pageName: `${provider} Whatsapp breakout:Confirm breakout`,
      pageSubSection1: `${provider} Whatsapp breakout`,
      pageSubSection2: `${provider} Whatsapp breakout:Confirm breakout`,
      pageCategory: `${provider} Whatsapp breakout`,
    });
  });

  function onViewDisclaimerClick() {
    setIsDisclaimerModalOpen(true);
  }

  return (
    <Stack
      textAlign="center"
      py={2}
      height="100%"
      spacing={4}
      justifyContent="space-between"
    >
      <Stack spacing={2}>
        <Box
          src={logo}
          height={70}
          my={2}
          component="img"
          alt={t("WhatappBreakout.provider.logo", { provider })}
        />
        <Stack spacing={4}>
          <Typography variant="h2">
            {t(`WhatappBreakout.${provider}.title` as any)}
          </Typography>
          {(
            t<any, any, string[]>(`WhatappBreakout.${provider}.body`, {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography key={String(text + index)}>{text}</Typography>
          ))}
        </Stack>
        <ButtonWithAnalytics
          page="Whatsapp Breakout"
          text={t(`common.viewDisclaimer`)}
          intent="navigational"
          onClick={onViewDisclaimerClick}
          variant="text"
        >
          {t("common.viewDisclaimer")}
        </ButtonWithAnalytics>
      </Stack>
      <Stack direction="row" spacing={1} width="100%">
        <ButtonWithAnalytics
          page="Whatsapp Breakout"
          text={t("common.backButton")}
          intent="navigational"
          onClick={onBackButtonClick}
          variant="outlined"
          sx={{
            width: "50%",
          }}
        >
          {t("common.backButton")}
        </ButtonWithAnalytics>
        <ButtonWithAnalytics
          page="Whatsapp Breakout"
          text={t("common.backButton")}
          intent="navigational"
          color="whatsApp"
          fullWidth
          component="a"
          target="_blank"
          href={url}
          sx={{ alignItems: "center" }}
          aria-label={t("WhatappBreakout.openWhatsapp")}
          startIcon={<IconLoader icon="WhatsAppIcon" />}
        >
          {t("WhatappBreakout.openWhatsapp")}
        </ButtonWithAnalytics>
      </Stack>
      <WhatsappBreakoutDisclaimerModal
        isOpen={isDisclaimerModalOpen}
        onClose={() => setIsDisclaimerModalOpen(false)}
        type={provider}
      />
    </Stack>
  );
}

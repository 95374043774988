import { Box, Divider, Stack, Typography } from "@mui/material";
import Panel from "@/features/Common/components/Panel";
import IconLoader from "@/features/Common/components/IconLoader";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";
import {
  convertPxToRem,
  formatDateToReadableString,
  getShortDayFromDate,
} from "@/utils";
import {
  PathologyOrder,
  PathologyOrderStatus,
  requiresPreparationInstructions,
} from "@/services/core-api-adapter";
import theme, { defaultFullscreenPageStyling } from "@/theme";
import { getConfigurationVariable } from "@/services/configuration-adapter";
import CircledBackButton from "@/features/Common/components/CircledBackButton";
import { trackEvent } from "@/services/analytics-adapter";

interface PathologyOrderSummaryProps {
  order: PathologyOrder;
  paymentDate: string;
  onViewResultsClick: () => void;
  onViewReceiptClick: () => void;
  onBackButtonClick: () => void;
  onHowToPrepareClick: () => void;
}

export default function PathologyOrderSummary({
  order,
  paymentDate,
  onViewReceiptClick,
  onViewResultsClick,
  onBackButtonClick,
  onHowToPrepareClick,
}: PathologyOrderSummaryProps) {
  const { t } = useTranslation();

  const isOrderPending = order.status === PathologyOrderStatus.PENDING;

  const isOrderCompleted =
    order.status ===
    (PathologyOrderStatus.COMPLETED || PathologyOrderStatus.TEST_SUBMITTED);

  const PATHOLOGY_FACILITY_LINK =
    getConfigurationVariable("VITE_APP_UNU_HEALTH_PATHOLOGY_FACILITY_LINK") ||
    "https://www.ampath.co.za/labs";

  function onFindAFacilityClick() {
    trackEvent({
      event: "action.pathologyPanelFindAFacilityClicked",
      source: "Pathology order summary",
    });
  }

  return (
    <Stack spacing={3} sx={{ ...defaultFullscreenPageStyling }}>
      <CircledBackButton showLabel onClick={onBackButtonClick} />
      <Stack justifyContent="center" textAlign="center" spacing={1}>
        <Typography variant="h2" component="h1">
          {t("PathologyOrderSummary.title")}
        </Typography>
        <Box gap={1}>
          <Typography
            variant="body1"
            component="h2"
            color="neutral.main"
            fontWeight="bold"
          >
            {t("PathologyOrderSummary.orderNumber.title")}
          </Typography>
          {!order.orderNumber ? (
            <Typography component="h3" variant="body1" color="neutral.main">
              {t("PathologyOrderSummary.orderNumber.notAvailable")}
            </Typography>
          ) : (
            <Typography component="h3" variant="body1" color="neutral.main">
              {order.orderNumber}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          gap={2}
        >
          <Stack
            display="inline-flex"
            direction="row"
            alignItems="center"
            alignContent="center"
            alignSelf="flex-start"
            borderRadius={1}
            spacing={0.25}
            p={0.5}
            bgcolor="transparent"
            sx={{
              border: `2px solid ${theme.palette.accent1.main}`,
              height: "auto",
              width: "auto",
            }}
          >
            <IconLoader
              icon="CheckInCircleIcon"
              sx={{
                color: theme.palette.accent1.main,
                fontSize: convertPxToRem(16),
              }}
            />

            <Typography
              fontSize={convertPxToRem(10)}
              fontWeight="600"
              color="accent1.main"
            >
              {t("PathologyOrderSummary.status.paid")}
            </Typography>
          </Stack>
          {order?.created && (
            <Typography variant="body2" color="neutral.main">
              {`${getShortDayFromDate(new Date(paymentDate))}, ${formatDateToReadableString(new Date(paymentDate))}`}
            </Typography>
          )}
        </Box>
      </Stack>
      <Panel>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="body1" fontWeight="bold">
              {t(`PathologyOrderSummary.${order.panelChargeCode}.title`)}
            </Typography>
            {isOrderPending && (
              <Typography variant="h5" color="neutral.main" fontWeight={400}>
                {t("PathologyOrderSummary.pendingOrder.title")}
              </Typography>
            )}
          </Stack>
          {requiresPreparationInstructions(order.panelChargeCode) && (
            <ButtonWithAnalytics
              page="PathologyOrderSummary"
              text={t("PathologyOrderSummary.howToPrepare.button.title")}
              intent="navigational"
              variant="outlined"
              size="small"
              fullWidth
              onClick={onHowToPrepareClick}
            >
              {t("PathologyOrderSummary.howToPrepare.button.title")}
            </ButtonWithAnalytics>
          )}
          <Divider />
          <Stack spacing={2}>
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {t("PathologyOrderSummary.results.title")}
              </Typography>
              <Typography variant="body2">
                {t("PathologyOrderSummary.results.body")}
              </Typography>
            </Stack>
            <ButtonWithAnalytics
              page="PathologyOrderSummary"
              text={t("PathologyOrderSummary.results.CTA")}
              intent="navigational"
              onClick={onViewResultsClick}
              size="small"
              fullWidth
              disabled={isOrderCompleted ? false : true}
            >
              {t("PathologyOrderSummary.results.CTA")}
            </ButtonWithAnalytics>
          </Stack>
        </Stack>
      </Panel>
      <Panel>
        <Stack spacing={2}>
          <Box
            display="flex"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <IconLoader
              sx={{ fontSize: 30 }}
              color="primary"
              icon="InformationSymbolFilledCircleIcon"
            />
            <Typography variant="h4">
              {t("PathologyOrderSummary.findAFacility.panel.title")}
            </Typography>
          </Box>

          <ButtonWithAnalytics
            page={t("PathologyOrderSummary.title")}
            text={t("PathologyOrderSummary.findAFacility.button.title")}
            intent="navigational"
            component="a"
            role="link"
            variant="outlined"
            size="small"
            fullWidth
            target="_blank"
            onClick={onFindAFacilityClick}
            href={PATHOLOGY_FACILITY_LINK}
          >
            {t("PathologyOrderSummary.findAFacility.button.title")}
          </ButtonWithAnalytics>
        </Stack>
      </Panel>
      <Panel>
        <Stack spacing={2.5} p={1}>
          <Typography variant="h4">
            {t("PathologyOrderSummary.orderDetails.panel.title")}
          </Typography>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h4" fontWeight={400}>
              {t(`PathologyOrderSummary.${order.panelChargeCode}.title`)}
            </Typography>
            <Typography variant="h4" color="primary">
              {order.amountTotalFormatted}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {t("PathologyOrderSummary.orderDetails.panel.total.title")}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: convertPxToRem(26) }}
              color="primary"
            >
              {order.amountTotalFormatted}
            </Typography>
          </Box>
          <ButtonWithAnalytics
            page="PathologyOrderSummary"
            text={t("PathologyOrderSummary.findAFacility.button.title")}
            intent="navigational"
            size="small"
            fullWidth
            onClick={onViewReceiptClick}
          >
            {t("PathologyOrderSummary.orderDetails.panel.button.title")}
          </ButtonWithAnalytics>
        </Stack>
      </Panel>
    </Stack>
  );
}

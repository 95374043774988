import MaxWidthContainer from "@/features/Common/components/MaxWidthContainer";
import { styleInputDefault } from "@/theme";
import { convertPxToRem } from "@/utils";
import {
  Button,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useId, useState } from "react";

export default function LightBoxPaymentExamples() {
  const lastNameInputID = useId();
  const userId = useId();

  const [amountValue, setAmountValue] = useState("");
  const [userIdValue, setUserIdValue] = useState("");
  const [paymentObject, setPaymentObject] = useState({
    MerchantID: "3D1D80D3-68C6-44B3-B3B8-9B30EFC493FB",
    TransactionType: "Payment",
    Amount: "",
    Currency: "ZAR",
    MerchantReference: "MR2118300350",
    UserID: "",
    Channel: "",
    Checksum:
      "40E9C06805168E896955A683F00FE4915E0B8841D301B88503A5CE1C1E52CD16",
  });

  function handleSubmit(e: any) {
    e.preventDefault();
    const paymentObjectFinal = {
      ...paymentObject,
      Amount: amountValue,
      UserID: userIdValue,
    };
    setPaymentObject(paymentObjectFinal);

    window.hpp.payment(
      paymentObjectFinal,
      () => {},
      () => {}
    );
  }

  function onAmountInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setAmountValue(value);

    const paymentObjectFinal = {
      ...paymentObject,
      Amount: value,
    };

    setPaymentObject(paymentObjectFinal);
  }

  function onUserIdInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setUserIdValue(value);

    const paymentObjectFinal = {
      ...paymentObject,
      UserID: value,
    };

    setPaymentObject(paymentObjectFinal);
  }

  return (
    <MaxWidthContainer>
      <Stack>
        <Typography mb={5}>Lightbox payment examples</Typography>
        <form onSubmit={handleSubmit}>
          <Stack spacing={1}>
            <InputLabel htmlFor={lastNameInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                Amount
              </Typography>
            </InputLabel>
            <TextField
              id={lastNameInputID}
              value={amountValue}
              size="small"
              onInput={onAmountInput}
              inputProps={{
                autoComplete: "off",
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>
          <Stack spacing={1}>
            <InputLabel htmlFor={userId}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                User ID
              </Typography>
            </InputLabel>
            <TextField
              id={lastNameInputID}
              value={userIdValue}
              size="small"
              onInput={onUserIdInput}
              inputProps={{
                autoComplete: "off",
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>
          <Stack mt={3}>
            <Button
              fullWidth
              type="submit"
              disabled={!userIdValue || !amountValue}
            >
              Trigger payment
            </Button>
          </Stack>
        </form>
        <Stack>
          <Typography>Final object</Typography>
          <Typography>{JSON.stringify(paymentObject, null, 2)}</Typography>
        </Stack>
      </Stack>
      <Button
        component="a"
        href={"/home/get-medical-assistance/pathology/payment-redirect"}
        target="_blank"
      >
        Test payment redirect
      </Button>
    </MaxWidthContainer>
  );
}
